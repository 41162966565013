export default class disableElementService {
    static addDisabledClassOnSpecificElement(element, className = 'disableCurrentElementContent--spinner') {
        if (element) {
            element.classList.add(className);
        }
    }

    static removeDisabledClassOnSpecificElement(element, className = 'disableCurrentElementContent--spinner') {
        if (element) {
            element.classList.remove(className);
        }
    }

    static addDisabledClassOnBody(className = 'disableCurrentElementContent--spinner') {
        document.body.classList.add(className);
    }

    static removeDisabledClassOnBody(className = 'disableCurrentElementContent--spinner') {
        document.body.classList.remove(className);
    }

    static removeServerSideRenderClass() {
        const div = document.querySelector('div.hydratingInProcess');
        if (div) {
            div.classList.remove('hydratingInProcess');
        }
    }
}

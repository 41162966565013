/* global zaraz */
import { MdOutlinePersonOutline, MdOutlineSearch } from 'react-icons/md';

import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { TbWorld } from 'react-icons/tb';
import disableElementService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/disableElementService';
import reportService from '@PROJECT_ROOT/assets/responsive-frontend/js/services/reportService';
import serviceData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/mainAxiosService';
import { charToColor, backgroundColorToTextColor } from '@PROJECT_ROOT/assets/responsive-frontend/js/services/profileAvatarUtils';
import userData from '@PROJECT_ROOT/assets/responsive-frontend/js/services/userData';

// Između ostaloga pod komentarom zbog ovoga bug-a #57702
// import LocalStoreSearchParameters from '@PROJECT_ROOT/assets/responsive-frontend/js/services/LocalStoreSearchParameters';

const SearchForm = lazy(() => import(/* webpackChunkName: "responsiveFrontendSearchForm" */ '@PROJECT_ROOT/assets/responsive-frontend/js/parts/searchForm'));

export default class MobileFixedButtons extends React.Component {
    constructor(props) {
        super(props);
        this.pathExtension = 'mobile-dock/';
        this.state = {
            publicResources: null,
            isLoading: true,
            selectedFilters: {
                // ...LocalStoreSearchParameters.getAllSearchParametersStoredInLocalStorage(),
                isScrollListenerAdded: false,
                hasScrolledFromTop: false,
            },
            isDataAttributeTrue: false,
            isAccordionVisible: false,
        };
        this.getData = this.getData.bind(this);
        this.updateSelectedFilters = this.updateSelectedFilters.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.getClassName = this.getClassName.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        this.handleScroll();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const { isAccordionVisible } = this.state;
        this.setState({ hasScrolledFromTop: window.scrollY > 0 });
        if (isAccordionVisible && window.scrollY <= 0) {
            this.setState({ isAccordionVisible: false });
        }
    }

    handleResize() {
        const { publicResources, isScrollListenerAdded, isAccordionVisible } = this.state;
        const isSmallDevice = window.innerWidth < 992;
        if (isSmallDevice) {
            if (!publicResources) {
                this.getData();
            }
            this.mobileFixedButtonsContainer = this.mobileFixedButtonsContainer ?? document.getElementById('mobileFixedButtonsReact');
            const dataAttribute = this.mobileFixedButtonsContainer?.getAttribute('data-whatsapp');
            const isDataAttributeTrue = dataAttribute === 'true';
            const homeAttribute = this.mobileFixedButtonsContainer?.getAttribute('data-home');
            const isHome = homeAttribute === 'true';
            this.setState({ isDataAttributeTrue, isHome });
            if (isHome) {
                this.setState({ hasScrolledFromTop: window.scrollY > 0 });
                if (isAccordionVisible && window.scrollY <= 0) {
                    this.setState({ isAccordionVisible: false });
                }
            }
            if (isHome && !isScrollListenerAdded) {
                window.addEventListener('scroll', this.handleScroll);
                this.setState({ isScrollListenerAdded: true });
            } else {
                window.removeEventListener('scroll', this.handleScroll);
                this.setState({ isScrollListenerAdded: false });
            }
        }
    }

    getData() {
        const stateData = this.state;
        if (!stateData.publicResources) {
            serviceData
                .getData(this.pathExtension)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            publicResources: res.data,
                        });
                        return;
                    }
                    reportService.reportError(res);
                })
                .catch((err) => {
                    reportService.reportError(err);
                })
                .finally(() => {
                    this.setState({
                        isLoading: false,
                    });
                });
        }
    }

    getClassName() {
        const { isHome, hasScrolledFromTop } = this.state;
        if (!isHome) return '';
        return hasScrolledFromTop ? 'mobileFixedButtons--slide-up' : 'mobileFixedButtons--slide-down';
    }

    serializeStateToQueryString() {
        const { selectedFilters } = this.state;
        const queryString = Object.keys(selectedFilters)
            .filter((key) => {
                const value = selectedFilters[key];
                return value !== false && value !== null && value !== undefined && value !== '' && value !== 0;
            })
            .map((key) => {
                let value = selectedFilters[key];
                if (Array.isArray(value) || typeof value === 'object') {
                    value = JSON.stringify(value);
                }
                return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            })
            .join('&');

        return queryString;
    }

    updateSelectedFilters(updatedFilters, redirect) {
        this.setState((prevState) => ({
            selectedFilters: {
                ...prevState.selectedFilters,
                ...updatedFilters,
            },
        }), async () => {
            const stateData = this.state;
            // LocalStoreSearchParameters.setAllSearchParametersStoredInLocalStorage(stateData.selectedFilters);
            if (redirect) {
                const encryptedParams = this.serializeStateToQueryString(stateData.selectedFilters);
                disableElementService.addDisabledClassOnBody('disableCurrentElementContent--transparent');
                serviceData
                    .getData('quick-search-action-url/', `?${encryptedParams}`)
                    .then((res) => {
                        let redirectURL = res.data.url;
                        if (res.data.locationName) {
                            const location = encodeURIComponent(res.data.locationName);
                            const locationId = encodeURIComponent(res.data.locationIdFilterValue);
                            redirectURL = `${res.data.url}&location=${location}&locationId=${locationId}`;
                        }
                        window.location.href = redirectURL;
                    })
                    .catch((err) => {
                        reportService.reportError(err);
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                        disableElementService.removeDisabledClassOnBody('disableCurrentElementContent--transparent');
                    });
            }
        });
    }

    toggleAccordion() {
        this.setState((prevState) => ({
            isAccordionVisible: !prevState.isAccordionVisible,
        }));
    }

    render() {
        const userJWT = document.getElementById('userData').getAttribute('data-user');
        const user = userJWT === '0' ? null : userData.getDecodedDataFromTemplate();
        const { publicResources, isLoading, isAccordionVisible, isDataAttributeTrue } = this.state;

        let firstChar;
        let backgroundColor;
        let color;
        if (user) {
            firstChar = user.firstname ? user.firstname.charAt(0) : user.username.charAt(0);
            backgroundColor = charToColor(firstChar);
            color = backgroundColorToTextColor(backgroundColor);
        }

        return (
            <>
                {publicResources && !isLoading && (
                    <div id="mobileFixedButtons" className={`${this.getClassName()} d-lg-none`}>
                        <Suspense fallback="">
                            <SearchForm shouldRenderHeaderTitle={true} update={this.updateSelectedFilters} hide={true} isAccordionVisible={isAccordionVisible} isDataAttributeTrue={isDataAttributeTrue} toggleAccordion={this.toggleAccordion} parametersBreak={true} />
                        </Suspense>
                        <div className="mobileFixedButtonsInner">
                            <button
                                type="button"
                                className={`btn btn-default btnUserFavorites btnFixed ${isAccordionVisible ? 'btn-lowOpacity' : ''}`}
                                id="btnUserFavorites"
                                data-toggle="modal"
                                data-target="#userFavorites"
                                onClick={() => {
                                    if (typeof zaraz !== 'undefined' && zaraz.consent) {
                                        const marketing = zaraz.consent.get('KlIi');
                                        if (zaraz.track && marketing) {
                                            zaraz.track('LanguageSwitcherDockClick');
                                        }
                                    }
                                }}
                            >
                                <TbWorld size={20} color="#4BBDFA" />
                                <span>{publicResources.languageTitle}</span>
                            </button>
                            <button type="button" className="btn btn-default btnUserSearch btnFixed" id="btnUserSearch" onClick={this.toggleAccordion}>
                                <MdOutlineSearch size={20} color="#4BBDFA" />
                                <span>{publicResources.searchTitle}</span>
                            </button>
                            <button type="button" className={`btn btn-default btnUserMenu btnFixed mobileFixedUserIcon ${isAccordionVisible ? 'btn-lowOpacity' : ''}`} id="btnUserMenu" data-toggle="modal" data-target="#userMenu">
                                <div className="userCircleAvatarContainer">
                                    {!user ? (
                                        <MdOutlinePersonOutline />
                                    ) : (
                                        <div className="userCircleAvatar" style={{ backgroundColor, color }}>
                                            {firstChar}
                                        </div>
                                    )}
                                </div>
                                <span>{publicResources.profileTitle}</span>
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
const mobileFixedButtons = document.getElementById('mobileFixedButtonsReact');
ReactDOM.render(<MobileFixedButtons />, mobileFixedButtons);

const redMultiplier = 123;
const greenMultiplier = 456;
const blueMultiplier = 789;

const redLuminanceCoefficient = 0.299;
const greenLuminanceCoefficient = 0.587;
const blueLuminanceCoefficient = 0.114;

const luminanceThreshold = 186;

const rgbColorRange = 256;
const achromaticAdjustment = 50;

/**
 * Generiraj kromatsku boju na osnovu jednog slova
 * @param  {string} char slovo
 * @return {string}      hex boja u obliku "#000000"
 */
export function charToColor(char) {
    const code = char.codePointAt(0);

    let red = (code * redMultiplier) % rgbColorRange;
    let green = (code * greenMultiplier) % rgbColorRange;
    let blue = (code * blueMultiplier) % rgbColorRange;

    if (red === green && green === blue) {
        red = (red + achromaticAdjustment) % rgbColorRange;
        if (red === green && green === blue) {
            green = (green + achromaticAdjustment) % rgbColorRange;
        }
        if (red === green && green === blue) {
            blue = (blue + achromaticAdjustment) % rgbColorRange;
        }
    }

    return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
}

/**
 * Na osnovu pozadinske boje, izračunava koja boja bi više odgovarala za tekst
 * @param  {string} backgroundColor hex boja oblika "#000000"
 * @return {string}                 "#202020" ili "#FFFFFF", ovisno koja boja stvara bolji kontrast sa *backgroundColor*
 */
export function backgroundColorToTextColor(backgroundColor) {
    const red = parseInt(backgroundColor.slice(1, 3), 16);
    const green = parseInt(backgroundColor.slice(3, 5), 16);
    const blue = parseInt(backgroundColor.slice(5, 7), 16);

    const luminance = redLuminanceCoefficient * red
                    + greenLuminanceCoefficient * green
                    + blueLuminanceCoefficient * blue;

    return luminance > luminanceThreshold ? '#202020' : '#FFFFFF';
}
